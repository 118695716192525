import React from "react"
import { graphql } from "gatsby"
import { media, styled } from "../components/mediaCSS"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentHolder = styled.div`
  .franchisePic {
    width: 100%;
  }

  ${media.tablet_desktop`
.franchisePic {
    float: right;
    width: 50%;
  }
`}
`

const Content = styled.div``

export default ({ data }) => {
  const origPic = data.origPic

  return (
    <Layout>
      <SEO title="About Us" />
      <ContentHolder>
        <Img className="franchisePic" fluid={origPic.childImageSharp.fluid} />
        <Content>
          <p>FCF Restaurants, Inc 1320 Lake Street Fort Worth, TX 76102</p>
          <p>Main: 817-870-2571</p>
          <p>
            The best times for inquiries or comments are Monday – Friday between
            10am and 3pm.
          </p>
          <p>Our Story</p>
          <p>
            Mama’s Pizza was founded in 1968 by Ed Stebbins on East Rosedale
            Street in Fort Worth. The Connecticut native, whose mother was from
            Naples, Italy, was then a student at Texas Wesleyan University and
            opened the pizzeria right across the street from the university.
            Stebbins couldn’t think of a good name for the pizzeria, but decided
            upon Mama’s. In 1970, his mother-in-law, Elizabeth Biggs, joined the
            Mama’s staff and began taking orders behind the counter. She became
            known as “Mama,” and the moniker stuck
          </p>
          <p>
            Stebbins sold the pizzeria in March of 1970 to Frank and Chris
            Farkas, but Mama Biggs continued to work there for another 16 years,
            becoming a legend on the East Side of Fort Worth. “Mama” Biggs
            trained nearly all of the mangers during her tenure and inspired
            them with hard work and a tough spirit. She had no problems throwing
            out local neighborhood kids that just wanted to play the video
            games, and chased customers who walked out without paying their tabs
            down the sidewalk, demanding payment when she caught them. When she
            retired in 1988, the employees threw a surprise party for her and
            presented her with her old rolling pin from the days when she used
            to roll the dough by hand.
          </p>
          <p>
            Frank Farkas sold his stock of the pizzeria to his son Chris in
            1979. Chris added several locations on Berry Street and Camp Bowie
            Boulevard. He then franchised nine locations, bringing the total
            number of Mama’s stores to 18. However, the Savings and Loan debacle
            later in the 1980s decimated the stores. The Berry Street
            location–the lone remaining company store–was inherited by Jordan
            Scott when Chris Farkas passed away in 2003. Jordan’s 13 years’
            experience working at Mama’s, combined with that of Harlan Streater,
            the longest serving Mama’s employee, ensured the Mama’s tradition
            was in good hands.
          </p>
          <p>
            From the Berry Street beginning in 2003, three new locations were
            quickly added: Arlington on Fielder Road in 2004, Mansfield on
            Debbie Lane in December 2006, and Fort Worth on Camp Bowie Boulevard
            in 2008. Franchise rights originally sold in 1999 were repurchased
            by the current owner and franchising opportunities are now
            available. With four successful stores, a proven recipe both for
            pizza and store operations, Mama’s is now extending the purview of
            Mama’s via franchised locations within Texas. “I don’t want to
            oversee more company stores, but would rather enlarge the scope of
            Mama’s through well-trained, well-run franchise operations. I think
            we can better expand through franchising,” said Mama’s President,
            Jordan Scott
          </p>
          <p>
            In 2011 Mama’s opened the Keller franchise location on Heritage
            Trace Parkway, and in mid 2012 added a second franchise pizzeria,
            this one in Fort Worth on Bryant Irvin Road. In October 2013, the
            third franchise location was added on North Richland Hills Blvd. 26.
            Also in October 2013, Mansfield on Debbie Lane became a franchised
            location.
          </p>
          <p>
            About Our Pizza Mama’s Pizza specializes in an “East Coast” style
            pizza that is made fresh daily using the best ingredients we can get
            our hands on. Our dough is made daily. We use 100% real cheese made
            from milk, quality meats and fresh produce to top our exceptional
            pies. Mama’s pizzas are baked in hot brick ovens, giving our pizzas
            a just-right browned crust that is brushed with melted garlic
            butter. Our dedication to quality ingredients and equipment has made
            Mama’s the pizza of choice in Fort Worth and Tarrant County for
            decades. In fact, some call it a Fort Worth institution.
          </p>
          <p>
            Awards &amp; Accolades Mama’s Pizza has won an overwhelming number
            of accolades over the last 40 years, including Best Pizza, Best
            Family Dining, and Best Cheap Eats by the readers of the Fort Worth
            Star-Telegram, and Best Pizza by Fort Worth, Texas Magazine
            countless times. It has also won Best Place to Carb Up by Fort
            Worth, Texas Magazine. Other local newspapers have presented
            numerous other awards to our individual stores. Customer
            satisfaction and high quality products have been Mama’s watchword
            from the very beginning, and we strive every day to maintain–or
            improve upon–those high standards.
          </p>
        </Content>
      </ContentHolder>
    </Layout>
  )
}

export const query = graphql`
  query AboutUsQuery {
    origPic: file(relativePath: { eq: "mamaorig.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
